export const events = {
  view_post: [
    "post_id",
    "is_finance",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_fuel",
    "post_transmission",
    "post_cabin",
    "post_cylinder",
    "post_mileage",
    "post_drivetrain",
    "post_engine_size",
    "post_price",
    "marketing_theme",
    "logged_in",
    "listing_type",
    "product_position",
    "page_number",
    "is_rec",
  ],
  view_inspection: ["post_id"],
  view_image: ["post_id"],
  view_video: ["post_id"],
  view_360: ["post_id"],
  usp_view: ["post_id", "usp_type"],
  post_share: ["post_id", "share_medium"],
  add_favorite: ["post_id"],
  online_contact: ["post_id", "contact_type", "contact_location"],
  inspection_image_view: ["post_id", "main_section", "part_name"],
  inspection_share: ["post_id", "share_medium"],
  checkout: [
    "booking_type",
    "post_id",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
  ],
  survey_start: [
    "user_id",
    "survey_type",
    "order_id",
    "order_type",
    "post_condition",
  ],
  survey_progress: [
    "user_id",
    "survey_type",
    "order_id",
    "question_order",
    "order_type",
    "post_condition",
  ],
  survey_submit: [
    "user_id",
    "survey_type",
    "order_id",
    "order_type",
    "post_condition",
    "csat_score",
  ],
  order_document_upload: [
    "order_id",
    "order_type",
    "post_condition",
    "document_id_type",
    "status",
    "platform"
  ],
  order_document_download: [
    "order_id",
    "order_type",
    "post_condition",
    "document_id_type",
  ],
  user_signup: [
    "user_signup_status",
    "user_id",
    "ui_language",
    "user_city",
    "user_name",
    "referral_location",
  ],
  user_login: [
    "user_login_status",
    "user_id",
    "ui_language",
    "referral_location",
  ],
  user_logout: ["user_id", "ui_language"],
  sell_car_started: ["referral_location"],
  sell_car_details_submit: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "asking_price",
    "car_status",
  ],
  sell_car_contact_submit: [
    "car_make",
    "car_model",
    "car_trim",
    "car_year",
    "car_odometer_range",
    "car_city",
    "asking_price",
    "car_status",
    "status",
    "fail_reason",
  ],
  book_car: [
    "post_id",
    "is_finance",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "marketing_theme",
  ],
  cash_form_submit: [
    "post_id",
    "user_city",
    "discount_code",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "total_amount",
    "marketing_theme",
  ],
  cash_order_submit: [
    "post_id",
    "order_id",
    "user_id",
    "payment_option",
    "response_code",
    "response_message",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
    "marketing_theme",
    "payment_status",
    "total_amount",
    "discount_code",
  ],
  finance_form_submit: [
    "post_id",
    "post_price",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "salary",
    "salary_transfer",
    "status",
  ],
  finance_order_submit: [
    "post_id",
    "order_id",
    "user_id",
    "payment_option",
    "payment_status",
    "response_code",
    "response_message",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "post_exterior_color",
    "post_price",
  ],
  search: ["search_query", "interaction_type", "results_count", "page_number"],
  filter_search: [
    "filter_name",
    "filter_value",
    "results_count",
    "page_number",
  ],
  search_impression: [
    "search_query",
    // "filter_name",
    // "filter_value",
    "page_number",
    "results_count",
  ],
  browse_impression: ["category_path", "page_number", "results_count"],
  product_click: [
    "search_query",
    "listing_type",
    "product_position",
    "page_number",
  ],
  browse_cars: [
    "referral_location",
    "browse_variant",
    "variant_value",
    "page_number",
    "results_count",
  ],
  marketing_theme_click: [
    "post_id",
    "post_condition",
    "theme_type",
    "marketing_theme",
    "referral_location",
    "has_timer",
  ],
  emkan_lead: [
    "post_id",
    "post_price",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "salary",
  ],
  emkan_lead_apply: [
    "post_id",
    "post_price",
    "inventory_type",
    "post_make",
    "post_model",
    "post_trim",
    "post_year",
    "post_condition",
    "salary",
  ],
  validation_error:[
    "document_id_type",
    "error_description"
  ]
};

if(__IS_CSR__){
  window.lastEvent = null;
}

export const trackEvents = (event, data) => {
  if(window.lastEvent === event){
    return;
  }
  window.lastEvent = event;
  setTimeout(() => {
    window.lastEvent = null;
  },500);

  const params = {};

  if (events[event]) {
    events[event].forEach((element) => {
      params[element] =
        data[element] || data[element] === 0 ? data[element] : null;
    });

    console.log(event, JSON.stringify(params));

    PushDataLayerObject({
      event: event,
      ...params,
    });
  }
};
