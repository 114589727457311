var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
  return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import fetcher from "../../common/axiosInstance";
import { UNBXD_Filters, UNBXD_POSTS, UNBXD_SERVICES_POSTS, UNBXD_SELECTED_Filters, UNBXD_USPS, UNBXD_META_DATA, UNBXD_MARKETING_BANNER, UNBXD_DEAL, UNBXD_LOADING, SET_PREV_URL, SIMILAR_ITEMS, UNBXD_LOAD_MORE, UNBXD_SPECS, GET_FILTERS_SSR } from "../types";
import { apiUrl, serverUrl } from "@globalConfig";
import { isRangeFilter, mapFacets, mapSelected } from "@/components/SearchComponent/UnbxdComponents/UnbxdAsideFilters/utils";
import cookieClient from "react-cookies";
import { trackEvents } from "@/common/events/events";
import { api__doSearch } from "@/apis/search/search";
import { getAndClearTrackingReferralParams } from "@hooks/useHashToLocalStoreEvent";
export var loading = function loading(status) {
  return {
    type: UNBXD_LOADING,
    payload: status
  };
};
export var updateLoadMoreClicked = function updateLoadMoreClicked(val) {
  return {
    type: UNBXD_LOAD_MORE,
    payload: val
  };
};
export var setPreUrl = function setPreUrl(url) {
  return {
    type: SET_PREV_URL,
    payload: url
  };
};
export var getSearch = function getSearch(_a) {
  var filters = _a.filters,
    link = _a.link,
    page = _a.page,
    sort = _a.sort,
    cb = _a.cb,
    url = _a.url,
    req = _a.req,
    size = _a.size,
    search = _a.search,
    hash = _a.hash;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var data, searchResults, normalizedSearchQuery;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            delete filters.sort;
            if ((filters === null || filters === void 0 ? void 0 : filters.is_new) && typeof (filters === null || filters === void 0 ? void 0 : filters.is_new) === "string") {
              filters.is_new = filters.is_new === "true" || filters.is_new === "1" ? 1 : 0;
            }
            if ((filters === null || filters === void 0 ? void 0 : filters.odometer_to) && typeof (filters === null || filters === void 0 ? void 0 : filters.odometer_to) === "string") {
              filters.odometer_to = filters.odometer_to.replaceAll(",", "");
            }
            if ((filters === null || filters === void 0 ? void 0 : filters.odometer_from) && typeof (filters === null || filters === void 0 ? void 0 : filters.odometer_from) === "string") {
              filters.odometer_from = filters.odometer_from.replaceAll(",", "");
            }
            filters.text = filters.text || "";
            data = {
              filters: filters,
              link: link,
              page: page,
              sort: sort || "",
              size: size,
              new_path: !!((link === null || link === void 0 ? void 0 : link.includes("/autos")) || (link === null || link === void 0 ? void 0 : link.includes("/years")))
            };
            dispatch(loading(true));
            return [4 /*yield*/, api__doSearch(req, page, data, ["usps", "meta_tags"])];
          case 1:
            searchResults = _a.sent();
            dispatch({
              type: UNBXD_POSTS,
              payload: {
                products: searchResults.products,
                page: page
              }
            });
            dispatch({
              type: UNBXD_Filters,
              payload: mapFacets(searchResults.facets)
            });
            dispatch({
              type: UNBXD_SELECTED_Filters,
              payload: mapSelected(searchResults.selected)
            });
            dispatch({
              type: UNBXD_USPS,
              payload: searchResults.usps
            });
            dispatch({
              type: SIMILAR_ITEMS,
              payload: searchResults.similar_items
            });
            dispatch({
              type: UNBXD_META_DATA,
              payload: {
                seoData: searchResults.seo_data,
                totalPages: searchResults.total_pages,
                title: searchResults.title,
                productsCount: searchResults.products_count,
                category_path: searchResults.category_path,
                request_id: searchResults.request_id,
                metaTags: searchResults.meta_tags
              }
            });
            dispatch({
              type: UNBXD_MARKETING_BANNER,
              payload: {
                desktop: searchResults.marketing_banner_desktop,
                mobile: searchResults.marketing_banner_mobile
              }
            });
            dispatch({
              type: UNBXD_DEAL,
              payload: searchResults.deal
            });
            dispatch({
              type: UNBXD_SPECS,
              payload: searchResults.specs
            });
            if (!req && searchResults.page > 1) {
              normalizedSearchQuery = search.replace(/([&?])page=\d+/g, "");
              window.history.replaceState(null, "", "".concat(url + normalizedSearchQuery).concat(normalizedSearchQuery ? "&" : "?", "page=").concat(searchResults.page).concat(hash ? hash : ""));
            }
            if (__IS_CSR__) {
              trackSearchEvents(filters, searchResults, dispatch);
            }
            if (!link) {
              // need to check after testing the browse search
              dispatch(unbxdAnalytics({
                action: "search_impression",
                query: filters.text || "",
                url: serverUrl + url + search,
                requestId: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.request_id) || "N.A",
                pids_list: ((searchResults === null || searchResults === void 0 ? void 0 : searchResults.products) || []).map(function (product) {
                  return product.uniqueId;
                })
              }));
            }
            if (link && typeof window !== "undefined") {
              dispatch(unbxdAnalytics({
                action: "browse_impression",
                url: serverUrl + url + search,
                requestId: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.request_id) || "N.A",
                page: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.category_path) || "",
                page_type: "BOOLEAN",
                pids_list: ((searchResults === null || searchResults === void 0 ? void 0 : searchResults.products) || []).map(function (product) {
                  return product.uniqueId;
                })
              }));
            }
            if (cb) cb();
            dispatch(loading(false));
            return [2 /*return*/];
        }
      });
    });
  };
};
export var getFiltersSSR = function getFiltersSSR(_a) {
  var req = _a.req,
    id = _a.id;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            dispatch(loading(true));
            return [4 /*yield*/, fetcher(req).post("/search/deal?id=".concat(id))];
          case 1:
            res = _a.sent();
            dispatch({
              type: GET_FILTERS_SSR,
              payload: res.data.data
            });
            dispatch(loading(false));
            return [2 /*return*/];
        }
      });
    });
  };
};
export var getServicesPosts = function getServicesPosts(_a) {
  var filters = _a.filters,
    link = _a.link,
    page = _a.page,
    sort = _a.sort,
    cb = _a.cb,
    req = _a.req,
    size = _a.size;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var data, searchResults;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            dispatch(loading(true));
            data = {
              filters: filters,
              link: link,
              page: page,
              sort: sort || "",
              size: size,
              new_path: false
            };
            return [4 /*yield*/, api__doSearch(req, page, data)];
          case 1:
            searchResults = _a.sent();
            dispatch({
              type: UNBXD_SERVICES_POSTS,
              payload: {
                products: searchResults.products,
                page: page
              }
            });
            if (cb) cb();
            dispatch(loading(false));
            return [2 /*return*/];
        }
      });
    });
  };
};
export var cleanUpServicesPosts = function cleanUpServicesPosts() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        dispatch({
          type: UNBXD_SERVICES_POSTS,
          payload: {
            products: [],
            page: 1
          }
        });
        return [2 /*return*/];
      });
    });
  };
};
export var getUnbxdSuggestions = function getUnbxdSuggestions(lang, searchTerm, cb) {
  return function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var url, normalizedSearchTerm, res;
      var _a, _b;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            url = (process.env.RAZZLE_ENV_UNBXD_AUTOSUGGEST_URL || "").replace("{lang}", lang).replace("{apiUrl}", apiUrl);
            normalizedSearchTerm = searchTerm || "";
            return [4 /*yield*/, fetcher().get("".concat(url).concat(normalizedSearchTerm))];
          case 1:
            res = _c.sent();
            if ((res === null || res === void 0 ? void 0 : res.status) === 200) return [2 /*return*/, cb(((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.products) || [])];
            cb([]);
            return [2 /*return*/];
        }
      });
    });
  };
};
export var getUnbxdRecommendations = function getUnbxdRecommendations() {
  return function () {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        // turn off recommendations for now
        return [2 /*return*/];
      });
    });
  };
};
export var unbxdAnalytics = function unbxdAnalytics(data) {
  return function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var _a, uid, isNew, visitId, currentDate, expireTime;
      return __generator(this, function (_b) {
        if (data.action !== "visitor" && !data.requestId) return [2 /*return*/];
        _a = getOrCreateUnbxdUserId(), uid = _a.uid, isNew = _a.isNew;
        data.uid = uid;
        data.visit_type = isNew ? "first_time" : "repeat";
        visitId = cookieClient.load("visitId");
        if (!visitId) {
          visitId = "visitId-".concat(new Date().getTime(), "-").concat(Math.floor(Math.random() * 100000));
          currentDate = new Date();
          expireTime = new Date(currentDate.getTime() + 30 * 60000);
          // Save the cookie with the expiration time
          cookieClient.save("visitId", visitId, {
            path: "/",
            expires: expireTime
          });
        }
        if (!__IS_SSR__) {
          fetcher(null, true).post("/search/track", __assign(__assign({}, data), {
            visitId: visitId,
            referrer: getReferralURL()
          }))["catch"](function (e) {
            return Promise.resolve("Ignore search analytics error");
          });
        }
        return [2 /*return*/];
      });
    });
  };
};
export function getReferralURL() {
  var _a, _b;
  // Check if running in a browser environment
  if (typeof window !== "undefined" && window.dataLayer) {
    // Filter the dataLayer array for 'gtm.historyChange' events
    var filtered = window.dataLayer.filter(function (s) {
      return s.event === "gtm.historyChange";
    });
    if (filtered.length > 0) {
      // Access the last element's 'gtm.oldUrl' property and decode it
      var lastUrl = decodeURI((_b = (_a = filtered[filtered.length - 1]) === null || _a === void 0 ? void 0 : _a["gtm.oldUrl"]) !== null && _b !== void 0 ? _b : "");
      return lastUrl;
    }
  }
  // Fall back to document.referrer if available
  if (typeof document !== "undefined" && document.referrer) {
    return document.referrer;
  }
  // Return empty string if no referrer is found
  return "";
}
export var getOrCreateUnbxdUserId = function getOrCreateUnbxdUserId() {
  var UUID = cookieClient.load("UUID");
  var isUnbxdNewUser = false;
  if (!UUID) {
    UUID = "uid-".concat(new Date().getTime(), "-").concat(Math.floor(Math.random() * 100000));
    isUnbxdNewUser = true;
    cookieClient.save("UUID", UUID, {
      path: "/",
      maxAge: 3600 * 24 * 365 * 10,
      sameSite: true
    });
  }
  return {
    uid: UUID,
    isNew: isUnbxdNewUser
  };
};
var trackSearchEvents = function trackSearchEvents(filters, searchResults, dispatch) {
  var _a, _b, _c, _d, _e, _f;
  var hashObject = getAndClearTrackingReferralParams(false);
  var isBrowseSearch = hashObject.hasOwnProperty("referral_location");
  var isTextSearch = filters.text;
  if (isTextSearch) {
    var isQuerySuggest = window.searchSuggestion || null;
    trackEvents("search", {
      search_query: filters.text,
      results_count: searchResults.products_count || 0,
      interaction_type: isQuerySuggest ? "query suggest" : "user input",
      page_number: searchResults.page
    });
    return;
  }
  if (isBrowseSearch) {
    trackEvents("browse_cars", __assign(__assign({}, hashObject), {
      results_count: searchResults.products_count || 0,
      page_number: searchResults.page
    }));
    return;
  }
  var filterValue;
  if ((_a = window === null || window === void 0 ? void 0 : window.lastClickedFilter) === null || _a === void 0 ? void 0 : _a.length) {
    filterValue = isRangeFilter((_b = lastClickedFilter[0]) === null || _b === void 0 ? void 0 : _b.filterField) ? "from ".concat(window.lastClickedFilter[0].range_values.from, ", to ").concat(window.lastClickedFilter[0].range_values.to) : window === null || window === void 0 ? void 0 : window.lastClickedFilter.map(function (item) {
      return item.name_en;
    });
  }
  trackEvents("filter_search", {
    filter_name: ((_c = window === null || window === void 0 ? void 0 : window.lastClickedFilter) === null || _c === void 0 ? void 0 : _c.length) ? (_d = window === null || window === void 0 ? void 0 : window.lastClickedFilter[0]) === null || _d === void 0 ? void 0 : _d.filterField : null,
    filter_value: filterValue ? filterValue : null,
    results_count: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.products_count) || 0,
    page_number: searchResults.page
  });
  dispatch(unbxdAnalytics({
    action: "filter_search",
    filter_name: ((_e = window === null || window === void 0 ? void 0 : window.lastClickedFilter) === null || _e === void 0 ? void 0 : _e.length) ? (_f = window === null || window === void 0 ? void 0 : window.lastClickedFilter[0]) === null || _f === void 0 ? void 0 : _f.filterField : null,
    filter_value: filterValue ? filterValue : null,
    results_count: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.products_count) || 0,
    page_number: searchResults === null || searchResults === void 0 ? void 0 : searchResults.page,
    requestId: (searchResults === null || searchResults === void 0 ? void 0 : searchResults.request_id) || "N.A"
  }));
  window.lastClickedFilter = null;
};