// Add user data to browser local storage
import cookieClient from "react-cookies";
import fetcher from "../common/axiosInstance";

import { parseJsonSafely } from "./GlobalJavascriptFunction";

export const setUserToLocalStorage = (data) => {
  localStorage.setItem("user", JSON.stringify({ ...data, expire: Date.now() }));
};
// Get user data from browser local storage
export const getUserFromLocalStorage = () => {
  const userData = parseJsonSafely(localStorage.getItem("user"));
  if (userData) return userData;
};

// Get user data from browser local storage
export const isFavoritePost = (postId) => {
  if (typeof window !== "undefined") {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.favorites) {
      return userData.favorites.includes(postId);
    }
  }
  return false;
};

// Get user data from browser local storage
export const AddRemoveFromFavorites = (postId, addOperation = true) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  if (userData.favorites) {
    if (addOperation) userData.favorites.push(postId);
    else {
      const index = userData.favorites.indexOf(postId);
      if (index > -1) {
        // only splice array when item is found
        userData.favorites.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  }
  setUserToLocalStorage(userData);
};

export function setAccessToken(token) {
  cookieClient.save("accessToken", token, {
    path: "/",
    maxAge: 3600 * 24 * 365 * 10,
    sameSite: true,
  });
}

export async function logout() {
  cookieClient.remove("accessToken", { path: "/" });
  cookieClient.remove("user");
  setUserToLocalStorage(null);
  await fetcher().post("/customer/logout").catch((err) => {
    console.log("/customer/logout err",err);
    return null
  });
}
